

































import {Vue, Component, Prop} from "vue-property-decorator";
import CouponItemType from "./indexType";

@Component({name: "CouponItem"})
export default class CouponItem extends Vue implements CouponItemType{
    ActiveIcon = require("@/assets/icon/Coupon/CardListBackActive.png");
    NotActiveIcon = require("@/assets/icon/Coupon/CardListBackNot.png");
    HaveIcon = require("@/assets/icon/Coupon/HaveUsed.png");
    NotHaveIcon = require("@/assets/icon/Coupon/HaveOut.png");
    StartTime = ""
    EndTime = ""

    handleToPath(){
        //
    }

    @Prop(Object)
    data!:any
    get getData(){
        return this.data
    }

    @Prop(Number)
    index!:number
    get getIndex(){
        return this.index
    }
}
