


















import {Vue, Component} from "vue-property-decorator";
import LotteryBoxType from "./LotteryBox";
import ZoomPage from "@/impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import CouponItem from "@/views/Other/LotteryBox/components/Item/index.vue";

@Component({name: "LotteryBox",components:{ HeadTop,PullDownUpList,ListBottom,CouponItem }})
export default class LotteryBox extends ZoomPage implements LotteryBoxType{
    PullDown = false
    UpDownBool = false
    StopUp = false
    List = []

    activated(){
        //
    }

    handlePullDown(){
        //
    }

    handleUpDown(){
        //
    }

    handleUpData(bool=false){
        //
    }
}
